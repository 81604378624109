import React, { useState, useRef  } from 'react';
import '../Styles/Ingreso.css'
import Cabecera from './CabeceraControler';
import { useNavigate } from 'react-router-dom';
import RFI from './RfiControler';
import axios from 'axios';
//import FTPClient from 'ftp-client';

function RegEjemplar() {
  const navigate = useNavigate();
  const [preview, setPreview] = useState('');
  const [areteCode, setAreteCode] = useState('');
  const [cowName, setCowName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [breed, setBreed] = useState('');
  const [imagenPath, setImagenPath] = useState('');
  const fileInputRef = useRef(null);
  const [imagenUpload, setImagenUpload] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(''); 
  const [photoOption, setPhotoOption] = useState('select'); // Opción por defecto
  const isMobile = /Mobi|Android/i.test(window.navigator.userAgent); // Verificar si es móvil
  const fileInput = useRef(null);

  const handlePhotoOptionChange = (e) => {
    setPhotoOption(e.target.value);
    if (e.target.value === 'camera' && isMobile) {
      fileInputRef.current.click(); 
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        // Verifica que el código de arete no esté vacío
        if (!areteCode) {
            alert('Por favor, ingrese el código de arete antes de seleccionar una imagen.');
            e.target.value = ''; // Limpiar el campo de archivo
            return; // Salir de la función
        }

        // Crea un objeto URL para la vista previa
        setPreview(URL.createObjectURL(file));

        // Cambia el nombre del archivo al código de arete seguido de la extensión
        const newFileName = areteCode+'.jpeg'; // O puedes usar otra extensión si es necesario
        
        // Crea un nuevo archivo con el nuevo nombre
        const renamedFile = new File([file], newFileName, { type: file.type });

        // Guarda el archivo renombrado en el estado
        setImagenUpload(renamedFile);
    }
};

  const subirImagenFTP = async (e) => {
    const formData = new FormData();
    setImagenPath('https://mycowsrfi.com/dataimagenes/ejemplares/'+imagenUpload.name);
    formData.append('image', imagenUpload);
    try {
    const respuesta = await axios.post('https://cowsmyapi.mycowsrfi.com/api/subirImage',formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Asegúrate de establecer el tipo de contenido
      },
    });
      alert('La imagen se ha cargado correctamente.'); // Mensaje de éxito
    } catch (error) {
      console.error(error);
      alert('Error al cargar la imagen.'); // Mensaje de error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const datos = {
      codigo_arete: areteCode,
      nombre: cowName,
      fecha_nacimiento: birthDate,
      raza: breed,
      foto: imagenPath
    };
    const json = JSON.stringify(datos);
    try {
      const response = await axios.post('https://cowsmyapi.mycowsrfi.com/api/vaca/agregar', {
        json: json
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Navegar a otro formulario
      navigate('/RFI', { replace: true, 
        state: { areteCode, cowName,breed } 
       });
    } catch (error) {
      console.error('Error al enviar datos:', error);
    }
  };
  
  return (
    <div className='ingresocontainer'>
    <Cabecera />
    <br /> <br /> <br />
    <div className='container-cow'>
      <form id="cow-form" className='cow-form' onSubmit={handleSubmit}>
        <h2>Ingreso de Ejemplar</h2>
        <div className="form-group">
          <label htmlFor="arete-code">Código de Arete:</label>
          <input type="text" id="arete-code" name="arete-code" required value={areteCode} onChange={(e) => setAreteCode(e.target.value)} />
        </div>

        {/* Opción para seleccionar foto */}
        {isMobile ? (
          <div className="form-group">
            <label htmlFor="photo-option">Selecciona opción para foto:</label>
            <select id="photo-option" value={photoOption} onChange={handlePhotoOptionChange}>
              <option value="select">Seleccionar archivo</option>
              <option value="camera">Tomar foto</option>
            </select>
            <input 
              type="file" 
              ref={fileInputRef} // Usar ref para el input de archivo
              id="cow-picture" 
              name="cow-picture" 
              accept="image/*" 
              onChange={handleFileChange} 
              style={{ display: 'none' }} // Ocultar el input de archivo
            />
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="cow-picture">Foto del Ejemplar:</label>
            <input 
              type="file" 
              id="cow-picture" 
              name="cow-picture" 
              accept="image/*" 
              onChange={handleFileChange} 
            />
          </div>
        )}

        <div className="preview-container">
          <img id="preview" src={preview} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover', border: '1px solid #ccc', margin: '10px' }} />
          <button type="button" className='subirimagen' onClick={subirImagenFTP}>Subir Imagen</button>
         
        </div>

        <div className="form-group">
          <label htmlFor="cow-name">Nombre del Ejemplar:</label>
          <input type="text" id="cow-name" name="cow-name" required value={cowName} onChange={(e) => setCowName(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="birth-date">Fecha de Nacimiento:</label>
          <input type="date" id="birth-date" name="birth-date" required value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="breed">Raza:</label>
          <select id="breed" name="breed" required value={breed} onChange={(e) => setBreed(e.target.value)}>
            <option value="">Seleccione una raza</option>
            <option value="Jersey">Jersey</option>
            <option value="Holstein">Holstein</option>
            <option value="Brown Swiss">Brown Swiss</option>
          </select>
        </div>
        <button type="submit">Registrar</button>
      </form>
    </div>
  </div>
);

}

export default RegEjemplar;