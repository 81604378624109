import React, { useState, useEffect } from 'react';
import '../Styles/Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Registrousuario from './RegistroControler';
import { useNavigate } from 'react-router-dom'; // Corregido typo en 'eact-router-dom'
import Home from './HomeControler';
import { Link } from 'react-router-dom';
import checkToken from './jwtCheck';
import ForgotPassword from './OlvidecontrsenaControler';
import axiosInterceptor from './axiosInterceptor';
import SplashScreen from './SplashControler';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
function Login() {
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const backgroundImage = require('../assets/login.jpg');
  const navigate = useNavigate(); // Debes declarar useNavigate fuera de la función handleSubmit
  const [isTransparent, setIsTransparent] = useState(false);
  const [loginFormHidden, setLoginFormHidden] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulamos la carga de los componentes del formulario
      setTimeout(() => {
        setLoading(false);
      }, 2000); // 2 segundos
    }, []);
  
    if (loading) {
      return <SplashScreen />;
    }
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      const email = document.getElementById('username').value;
      const password = document.getElementById('password').value;
      const datos = {
        email,
        password
      };
      
      const json = JSON.stringify(datos);
      try {
        const response = await axios.post(' https://cowsmyapi.mycowsrfi.com/api/users/login', {
          json: json
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
          });
          const token = response.data.message;
          if(token == 'Usuario o Contraseña ingresados incorrectamente.'){
            alert(token);
            return;
          }
        if(token.length == null){
          alert(response.data.message.message);
          return ;
        } else{
          if(token.length != null){
            localStorage.setItem('token', token);
            try {
              const userData = await checkToken(token);
              if(userData.tipo_usuario === 'Administrador'){
               // alert('redirigiendo a usuarios');
                navigate('/Listausu', { replace: true });
              } else {
              navigate('/home', { replace: true });
              }
            } catch (error) {
              console.alert(error);
            }
          }
        }
        
        
      } catch (error) {
        alert("Error al ingresar datos. Por favor, revisa sus credenciales.");
      }
    };
  
  const handleForgotPassword = () => {
    navigate('/ForgotPassword', { replace: true }); 
  };
  const handleRegister = () => {
    navigate('/Registro', { replace: true }); // redirige a la ruta /register
  };
  return (
    <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
     <br/> <br/> <br/>
      <div className="containerlogin " id="containerlogin">
        <form id="login-form " onSubmit={handleSubmit}>
        <Link id="boton_imglo" to="/">
                <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="About" className='imglogin flip' style={{ marginTop: '-20vh' }} />  
              </Link>
        <h2 className='loti'>BIENVENIDO</h2>
        <br/><br/>
          <label htmlFor="username">
            <FontAwesomeIcon icon={faEnvelope} /> Correo electrónico:
          </label>
          <input
            type="email"
            id="username"
            className="form-control"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            required
          />
          <br />
          <br />
          <label htmlFor="password">
            <FontAwesomeIcon icon={faLock} /> Password:
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            className="form-control"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
         <FontAwesomeIcon
              icon={showPassword ? faEye :  faEyeSlash }
              onClick={() => setShowPassword(!showPassword)}
              className="password-icon"
            />
          <br />
          <br />
          <input type="submit" value="Login" className="btn-submit" />
          <br />
          <br />
          
          <a  className="forgot-link" id="forgot-password" onClick={handleForgotPassword}>
            Olvide contraseña
          </a>
           <br></br><br></br>
           <p>
           ¿No tienes cuenta? <a className='rgslogin' onClick={handleRegister}>Regístrate aquí</a>
          </p>
         
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      
    </div>

  );
}


export default Login;


