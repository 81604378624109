import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Styles/historial.css'
import { useNavigate, useLocation } from 'react-router-dom';
import Cabecera from './CabeceraControler';

function FormularioTabla() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.token;
  const location = useLocation();
  const navigate = useNavigate();
  const {cow_Name, arete_Code, score } = location.state || {};

  useEffect(() => {
    historial();
  }, []);

  const historial = async () => {
    try {
      const response = await axios.get(`https://cowsmyapi.mycowsrfi.com/api/rfi/obtenerRfi/${arete_Code}`, {
        headers: {
          Authorization: token,
        },
      });
      if (parseInt(response.data.code) === 200) {
        setData(response.data.datos); // Actualiza el estado con los datos obtenidos
        
      } else {
        setError(new Error(response.data.message)); // Manejo de errores
      }
    } catch (error) {
      console.error(error);
      setError(error); // Manejo de errores
    } finally {
      setLoading(false); // Cambia el estado de carga
    }
  };

  if (loading) return <div>Cargando...  </div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Cabecera/>
      
      <br/><br/><br/>
  <div className='fnd'>
    <div className="formulario-tabla">
        <h1 className='titulo'> Historial de Información RFI</h1>
      <table className='tbl_histrial1'>
       
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Código de Arete</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
            <tr >
              <td>{cow_Name}</td>
              <td>{arete_Code}</td>
              <td>{score}</td>
            </tr>
          
        </tbody>
      </table>
      <table className='tbl_historial2'>
        <thead>
          <tr>
            <th  className='linea-divisoria' colSpan="9">Sistema Mamarió </th>
            <th className='linea-divisoria' colSpan="2"> Fuerza Lechera</th>
            <th  className='linea-divisoria'colSpan="5">Patas y Pezuñas</th>
            <th  className='linea-divisoria' colSpan="3">Tren Anterior y Capacidad</th>
            <th  className='linea-divisoria' colSpan="2">Grupa</th>
            <th  className='linea-divisoria' colSpan="2">Datos</th>
          </tr>
          <tr>
            <th>I.A.U</th>
            <th>C.P.A</th>
            <th>L.P</th>
            <th>P.U</th>
            <th>A.U.P</th>
            <th>L.S.M</th>
            <th>C.P.P</th>
            <th>A.U.T</th>
            <th>I.U</th>
            <th>ANG</th>
            <th>FORT</th>
            <th>A.D.P</th>
            <th>P.V.L</th>
            <th>LOC</th>
            <th>P.V.P</th>
            <th>C.F</th>
            <th>EST</th>
            <th>PROF</th>
            <th>C.C</th>
            <th>AC.G</th>
            <th>AN.G</th>
            <th>Ultima Modificación</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.insercion_anterior_de_la_ubre}</td>
              <td>{item.colocacion_de_pezon_anterior}</td>
              <td>{item.longitud_de_pezon}</td>
              <td>{item.profundidad_de_la_ubre}</td>
              <td>{item.altura_de_la_ubre_posterior}</td>
              <td>{item.ligamentos_suspensor_medio}</td>
              <td>{item.colocacion_de_pezon_posterior}</td>
              <td>{item.anchura_de_la_ubre_trasera}</td>
              <td>{item.inclinacion_de_la_ubre}</td>
              <td>{item.angularidad}</td>
              <td>{item.fortaleza}</td>
              <td>{item.angulo_de_pezunas}</td>
              <td>{item.patas_vista_lateral}</td>
              <td>{item.locomocion}</td>
              <td>{item.patas_vista_posterior}</td>
              <td>{item.coxo_femoral}</td>
              <td>{item.estatura}</td>
              <td>{item.profundidad}</td>
              <td>{item.condicion_corporal}</td>
              <td>{item.angulo_de_la_grupa}</td>
              <td>{item.ancho_de_la_grupa}</td>
              <td>{item.fecha_ultima_mod}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <a href="#" onClick={() => navigate(-1)} className="regrsarh">
            Regresar
          </a>
    </div>
    </div>
    </div>
  );

}


export default FormularioTabla;