import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const DynamicQRCodeGenerator = () => {
  const [qrCodeValue, setQrCodeValue] = useState('https://example.com');
  const qrCodeSize = 256; // fixed size
  const qrCodeLevel = 'H'; // fixed error correction level (30%)

  const handleQrCodeValueChange = (event) => {
    setQrCodeValue(event.target.value);
  };

  const handleDownloadClick = () => {
    const qrCodeCanvas = document.getElementById('qrCodeCanvas');
    const dataURL = qrCodeCanvas.toDataURL();
    const a = document.createElement('a');
    a.href = dataURL;
    a.download = 'qrCode.png';
    a.click();
  };

  return (
    <div>
      <h2>Generador de Código QR Dinámico</h2>
      <form>
        <label>
          Valor del código QR:
          <input type="text" value={qrCodeValue} onChange={handleQrCodeValueChange} />
        </label>
        <QRCodeCanvas
          id="qrCodeCanvas"
          value={qrCodeValue}
          size={qrCodeSize}
          level={qrCodeLevel}
          includeMargin={true}
        />
        <button onClick={handleDownloadClick}>Descargar Código QR</button>
      </form>
    </div>
  );
};

export default DynamicQRCodeGenerator;