import { useEffect, useRef, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Cabecera from './CabeceraControler';
import '../Styles/QR.css'
import { useNavigate } from 'react-router-dom';

function QrReader(){
  const [resultadoEscaner, setResultadoEscaner] = useState(null);
  const scannerRef = useRef(null); // Creamos una referencia al scanner
  const navigate = useNavigate();
  useEffect(() => {
    if (!scannerRef.current) { // Verificamos si el scanner ya ha sido renderizado
      const scanner = new Html5QrcodeScanner('QrReader', { 
        fps: 5 
      });
      scannerRef.current = scanner; // Asignamos la referencia al scanner
      scanner.render(success, error);
    }
    function success (resultado){
      scannerRef.current.clear();
      setResultadoEscaner(resultado);
    }
    function error (error){
      console.log(error);
    }
  },[]);

  const enviarPDF = () => {
    navigate(`/Ejemplar/${resultadoEscaner}`);
  
  };
  return (
    <div className='fondoqr'>
    <div>
      <Cabecera/>
      <br/><br/><br/>
      <div className="contenedorQRReader">
        <div id='QrReader'>Escanea un código QR</div>
      </div>
      {resultadoEscaner && (
        <div id="resultadoEscaner">
          <h2>Resultado del escaneo:</h2>
          <p>{enviarPDF()}</p>
        </div>
      )}
    </div>
    </div>
  );
}

export default QrReader;