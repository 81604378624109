import React, { useState, useEffect } from 'react';
import JsBarcode from 'jsbarcode';

function Code128Barcode() {
  const [code, setCode] = useState('');
  const [barcode, setBarcode] = useState(null);
  let barcodeCanvas; // Declare as let instead of const

  const handleInputChange = (event) => {
    setCode(event.target.value);
  };

  const handleBarcodeRender = () => {
    if (code.trim() !== '') { // Agregar validación para evitar códigos vacíos
      JsBarcode(barcodeCanvas, code, {
        format: 'code128',
        width: 3,
        height: 100,
        displayValue: true,
      });
    }
  };

  const handleDownload = () => {
    const barcodeDataUrl = barcodeCanvas.toDataURL();
    const link = document.createElement('a');
    link.href = barcodeDataUrl;
    link.download = 'codigo-de-barras.png';
    link.click();
  };

  useEffect(() => {
    handleBarcodeRender(); // Call handleBarcodeRender when code changes
  }, [code]);

  return (
    <div>
      <input type="text" value={code} onChange={handleInputChange} />
      <button onClick={handleDownload}>Descargar código de barras</button>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <canvas id="barcode-canvas" width={300} height={100} ref={(canvas) => barcodeCanvas = canvas} /> 
      </div>
    </div>
  );
}

export default Code128Barcode;