import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Styles/olvidecontrsena.css'

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !email.includes('@')) {
      setError('Por favor, ingrese un correo electrónico válido');
      return;
    }
    const datos = {
      email,
    };
    
    try {
      const response = await axios.post('https://cowsmyapi.mycowsrfi.com/api/users/restorepass', datos);
      setSuccess(true);
      setError(null);

      // Redirigir al login después de 3 segundos
      setTimeout(() => {
        navigate('/login'); // Cambia '/login' a la ruta de tu página de inicio de sesión
      }, 4000); // 3000 ms = 3 segundos

    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Error desconocido. Intente nuevamente más tarde.');
      }
    }
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className='container-contra'>
      <div className="forgot-password-form">
        <h2>Olvidé contraseña</h2>
        <br />
        <form onSubmit={handleSubmit} className='olvide'>
          <label>
            Correo electrónico:
          </label>
          <input type="email" className='emailolvide' value={email} onChange={handleInputChange} />
          <button type="submit" className='glassmorphism-button'><a className='texto'>Enviar</a></button>
        </form>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {success && (
          <div style={{ color: 'Black bean' }}>
            Se ha enviado un correo electrónico con instrucciones para restablecer su contraseña.
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;