import { useState, useLayoutEffect } from 'react';
import '../Styles/Splash.css';
function SplashScreen() {
  return (
   <div className="splash-screen">
     
      <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="Imagen de fondo" />
      <div className="progress-bar visible" />
      <p>Cargando...</p>
       
    </div>
  );
}

export default SplashScreen 