import React, { useState, useEffect } from 'react';
import '../Styles/Registro.css'
import Login from './LoginControler';
import { useNavigate } from 'react-router-dom';
import SplashScreen from './SplashControler';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';

function Registro() {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    // Simulamos la carga de los componentes del formulario
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 segundos
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = document.getElementById('username').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
     //Validación de contraseña
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('La contraseña debe tener al menos 8 caracteres, números, letras y caracteres especiales.');
      return;
    }
    
    const datos = {
      username,
      email,
      password
    };
    
    const json = JSON.stringify(datos);
    
    try {
      const response = await axios.post('https://cowsmyapi.mycowsrfi.com/api/users/register', {
        json: json
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      alert("¡Registro exitoso! Tus datos han sido registrados correctamente.");
      navigate('/Login');
    } catch (error) {
      setError(error.message);
      alert("Error al registrar. Por favor, inténtalo de nuevo.");
    }
  };



  const handleLogin = () => {
    navigate('/Login', { replace: true }); // redirige a la ruta /register
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]*$/;
    if (!passwordRegex.test(event.target.value)) {
      setError('La contraseña debe tener al menos 8 caracteres, un dígito, una letra mayúscula, una letra minúscula y un carácter especial.');
    } else {
      setError(null);
    }
  };
  return (
    <div className='register-form-background'>
      <br />
      <div className="register-form-container">
        <div className="coffee-cup">
          <div className="cup">
            <h2>Registro de Usuarios</h2>
            <form id="register-form" onSubmit={handleSubmit}>
              <label htmlFor="username">Nombre de usuario:</label>
              <input
                type="text"
                id="username"
                placeholder="username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                required
              />
              <label htmlFor="email">Correo electrónico:</label>
              <input
                type="email"
                id="email"
                placeholder="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
              <label htmlFor="password">
               Contraseña:</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="password"
                value={password}
                onChange={handlePasswordChange}
                required
               />
                 <FontAwesomeIcon
               icon={showPassword ? faEye :  faEyeSlash }
               onClick={() => setShowPassword(!showPassword)}
               className="password-iconre"
               />
              <br/>
              <button type="submit">Registrarse</button>
              <br />
              <br />
              <p>
                ¿Ya tienes cuenta? <a className="link-pointer" onClick={handleLogin}>Ingresa aquí</a>
              </p>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registro;