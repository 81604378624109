import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Styles/admin.css'
import Cabecera from './CabeceraControler';
import { useNavigate } from 'react-router-dom';
// Define el enum de tipos de usuarios
const UserTypes = {
  
  Empleado: 'Empleado',
  Observador: 'Observador',
};

// Simula una llamada a un API para obtener los datos de los usuarios


function Listausu() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const token = localStorage.token;
  const [type, setType] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newType, setNewType] = useState('');
  const [userDel, setUserDel] = useState('');
  const navigate = useNavigate();
  let userDelete = '';
  //const email = document.getElementById('email').value;

  useEffect(() => {
    getUsers();
  }, []);


if (!token) {
  return (
    <div className='containermsj '>
    <div className='mensaje'>
      <h2>Debe iniciar sesión para acceder a esta página</h2>
      <p className='msjle'>Por favor, inicie sesión para continuar</p>
      <button className='btnmensaje' onClick={() => navigate('/Login')}><a className='textomsj'>Ingresar</a></button>
    </div>
    </div>
  );
}
  const getUsers = async () => {
    try {
      const response = await axios.get(`https://cowsmyapi.mycowsrfi.com/api/users/listarUsuarios`,
        {
          headers:{
           Authorization: localStorage.token
          }
        }
      );
      if(response.data.code == 200){
        setUsers(response.data.data);
      } else {
        console.error(response.data.message);
      }
      //
    } catch (error) {
      console.error(error);
    }
  };

  
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const filteredUsers = users.filter((user) => {
    const lowerCaseSearch = search.toLowerCase(); // Convertimos el texto de búsqueda a minúsculas
    return (
      (user.name.toLowerCase().includes(lowerCaseSearch) || // Filtrar por nombre
       user.email.toLowerCase().includes(lowerCaseSearch)) && // Filtrar por email
      (type === '' || user.tipo_usuario === type) // Filtrar por tipo de usuario
    );
  });

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
  };

  const handleDeleteUser = async (user) => {
    let confirmacion = window.confirm("¿Desea eliminar el usuario?");
   if(confirmacion){
    const datos ={
      estado: 'Inactive',
      }
      const json = JSON.stringify(datos);
      try {
        const response = await axios.put(`https://cowsmyapi.mycowsrfi.com/api/users/delete/${user}`, {
          json: json
        }, { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.token
        }
      });
      if(response.data.code != 200){
        console.error(response.data.message);
      }else{
        alert('Usuario Eliminado correctamente');
        window.location.reload();
      }
      }catch (error) {
        console.error(error);
      }
   } else {
    window.location.reload();
   }
};


  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleNewTypeChange = (event) => {
    setNewType(event.target.value);
    
  };

  const handleUpdateUser = async () => {
    // Implementar lógica para actualizar usuario
    const datos ={
      email: selectedUser.email,
      tipo_usuario: newType
    }
    const json = JSON.stringify(datos);
    try {
      const response = await axios.put('https://cowsmyapi.mycowsrfi.com/api/users/cambioRol', {
        json: json
      },{ headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.token
      }});
    }catch (error) {
      console.error(error);
      }
    setModalVisible(false);
    window.location.reload();
  };

  return (
    <div className='fondoadmin' >
    <Cabecera/>
    <br/>  <br/>  <br/>
    <div className='listausuarios'>
      <h2>Lista de usuarios</h2>
      <br/>
      <input type="search" value={search} onChange={handleSearch} placeholder="Buscar" className='buscaradmin'/>
      <select value={type} onChange={handleTypeChange}>
        <option value="">Todos</option>
        <option value={UserTypes.Empleado}>Empleado</option>
        <option value={UserTypes.Observador}>Observador</option>
      </select>
      <div className='tabla-container'>
      <table className='tabla'>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Tipo</th>
            <th>Estado</th>
            <th>Acciones</th>
            
          </tr>
        </thead>
        <tbody >
          {filteredUsers.map((user) => (
            <tr key={user.id} className={user.estado === 'Inactive' ? 'row-inactive' : ''}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.tipo_usuario}</td>
              <td>{user.estado}</td>
              <td>
                <button className='btntabla' onClick={() => handleEditUser(user)}>Modificar</button>
                &nbsp;&nbsp;
                <button className='btntablaeli' onClick={() => handleDeleteUser(user.email)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {modalVisible && (
        <div className="modal-container">
        <div className="modal">
          <h2 className='popupti'>Modificar usuario</h2>
          <br/>
          <p className='popuple'>Nombre: {selectedUser.name}</p>
          <br/>
          <p>Email: {selectedUser.email}</p>
          <br/>
          <select className='popupselect' value={newType} onChange={handleNewTypeChange}>
          <option >Seleccione nueva opción</option>
            <option value={UserTypes.Empleado}>Empleado</option>
            <option value={UserTypes.Observador}>Observador</option>
          </select>
          <br/><br/>
          <button className='btnpop' onClick={handleUpdateUser}>Actualizar</button>
          &nbsp;&nbsp;
          <button className='btnpopeli' onClick={handleModalClose}>Cancelar</button>
        </div>
    
    </div>
    )}
    <button className='actualizarbtn' onClick={() => console.log('Actualizar datos')}><a className='textoadmin'>Actualizar datos</a></button>
  </div> 
  </div>
);
}

export default Listausu;