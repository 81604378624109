
import '../Styles/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cabecera from './CabeceraControler';
import React, { useState, useEffect } from 'react';
import SplashScreen from './SplashControler';
import {  useParams, useNavigate } from 'react-router-dom';
import Galeria from './ListControler';


function Home() {
  const token = localStorage.token;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulamos la carga de los componentes del formulario
      setTimeout(() => {
        setLoading(false);
      }, 2000); // 2 segundos
    }, []);
  
    if (loading) {
      return <SplashScreen />;
    }

  if (!token) {
    return (
      <div className='containermsj '>
      <div className='mensaje'>
        <h2>Debe iniciar sesión para acceder a esta página</h2>
        <p className='msjle'>Por favor, inicie sesión para continuar</p>
        <button className='btnmensaje' onClick={() => navigate('/Login')}><a className='textomsj'>Ingresar</a></button>
      </div>
      </div>
    );
  }
  

    const handleImageClick = (raza) => {
      //alert('redirigiendo a '+raza)
     navigate(`/Galeria/${raza}`);
    };
  return (
    <div className="home-container">

       <Cabecera/>
       <br/><br/><br/>
      <main>
        <br/>
        <h2
          style={{
            textAlign: 'center',
            fontSize: '40px',
            fontFamily: 'Impact',
            color: '#dcdcdc',
          }}
        >

          BIENVENIDO A MY COWNS RFI
        </h2>
        <br/>
        <section className="galleryhome">

        <div className="gallery-itemhome">
          <p className='titgaleria' id='raza1'>Brown Swiss</p>
            <img src={require('../assets/brownswii.png')} alt="Limousin" onClick={() => handleImageClick('BrownSwiss')} />
           
            <p id='titgaleria1'>Breve descripción: La raza Brown Swiss se caracteriza por:</p>
            <ul className='caracgaleria'>
              <li >Su alta producción de leche, que puede alcanzar hasta 10,000 litros por lactancia.</li>
              <li >Su temperamento dócil y tranquilo. Son animales fáciles de manejar, lo que facilita su cuidado y manejo en granjas</li>
              <li >Su  pelaje marrón claro que es denso y resistente, lo que les proporciona una buena protección contra las inclemencias del tiempo.</li>
            </ul>
          </div>
          
          <div className="gallery-itemhome">
          <div >
          <p className='titgaleria' id='raza2'>Holstein</p>
            <img src={require('../assets/holstein.png')} alt="Holstein" onClick={() => handleImageClick('Holstein')} />
           
            </div>
            
            <p id='titgaleria1'>Breve descripción: La raza Holstein se caracteriza por:</p>
            <ul className='caracgaleria'>
              <li >Su alta producción de leche, con un promedio de 9.000 litros por lactancia.</li>
              <li >Con un manejo adecuado, los Holstein pueden ser muy dóciles y cooperativos, lo que los hace ideales para la producción lechera a gran escala.</li>
              <li >Su pelaje blanco y negro, que no solo es visualmente impactante, sino que también tiene un propósito funcional.</li>
            </ul>
          </div>
          
          <div className="gallery-itemhome">
            <p className='titgaleria' id='raza3'>Jersey</p>
            <img src={require('../assets/jersey.png')} alt="Holstein" onClick={() => handleImageClick('Jersey')} />
           
            <p id='titgaleria1'>Breve descripción: La raza Jersey se caracteriza por:</p>
           
            <ul className='caracgaleria'>
              <li >Su alta producción de leche, con un promedio de 6.000 litros por lactancia.</li>
              <li >Son conocidos por su naturaleza curiosa y amigable. Su temperamento es generalmente muy dócil, lo que facilita su manejo en el establo.</li>
              <li >Su  pelaje de color marrón claro a oscuro, que puede variar en tonalidades.</li>
            </ul>
          </div>
     
         
        </section>
      </main>
    </div>
  );
}

export default Home;