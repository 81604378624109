import logo from './logo.svg';
import './App.css';
import Home from './Controllers/HomeControler';
import { BrowserRouter, Route, Navigate, Routes, HashRouter } from 'react-router-dom';
import Inicio from './Controllers/InicioControler';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Login from './Controllers/LoginControler';
import About from './Controllers/About';
import Registro from './Controllers/RegistroControler';
import ForgotPassword from'./Controllers/OlvidecontrsenaControler';
import Galeria from './Controllers/ListControler';
import RegEjemplar from './Controllers/IngresoControler';
import RFI from './Controllers/RfiControler';
import Listausu from './Controllers/CrudusuControler';
import ModificarEjemplar from './Controllers/ModEjemControler';
import Formulario from './Controllers/ModiuserControler';
import QrReader from './Controllers/BuscarqrControler';
import CowForm from './Controllers/PdfControler'
import DynamicQRCodeGeneratorWithImage from './Controllers/qrgenrador'
import  Code128Barcode from './Controllers/Barrasgnerador'
import UnificadoCodeGenerator from './Controllers/qrybarras'
import FormularioTabla from './Controllers/cowmodi' 

function App() {
  return (
    <div>
    <HashRouter>
 
    <Routes>
    
      <Route path="/" element={< Inicio/>} exact > </Route>
      <Route path="/Home" element={<Home/>} exact > </Route>|
      <Route path='/Login' element={<Login/>} exact></Route>
      <Route path='/About' element={<About/>} exact></Route>
      <Route path='/Registro' element={<Registro/>} exact></Route>
      <Route path='/ForgotPassword' element={<ForgotPassword/>} exact></Route>
      <Route path='/Galeria/:raza' element={<Galeria />} exact></Route>
      <Route path='/RegEjemplar' element={<RegEjemplar/>} exact></Route>
      <Route path='/RFI' element={<RFI/>} exact></Route>
      <Route path='/Listausu' element={<Listausu/>} exact></Route>
      <Route path='/ModificarEjemplar' element={<ModificarEjemplar/>} exact></Route>
      <Route path='/Perfil' element={<Formulario/>} exact></Route>
      <Route path='/Reader' element={<QrReader/>} exact></Route>
      <Route path='/Ejemplar/:codigo_arete' element={<CowForm/>} exact></Route>
      <Route path='/qrgenerador' element={<DynamicQRCodeGeneratorWithImage/>} exact></Route>
      <Route path='/bagenerador' element={<Code128Barcode/>} exact></Route>
      <Route path='/generador' element={<UnificadoCodeGenerator/>} exact></Route>
      <Route path="/Historial" element={<FormularioTabla/>} exact > </Route>|
      <Route path='/ModEjemplar' element={<ModificarEjemplar/>} exact></Route>
      


      </Routes>
    </HashRouter>.
    </div>
  );
}



export default App;
