import React, { useState, useEffect } from 'react';
import '../Styles/modiuser.css'
import Cabecera from './CabeceraControler';
import checkToken from './jwtCheck';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Importa los íconos
const USERNAME_INPUT = 'username';
const PASSWORD_INPUT = 'newPassword';
const CONFIRM_PASSWORD_INPUT = 'confirmPassword';
const ERROR_MESSAGE = 'Error al actualizar la cuenta';


function Formulario() {
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para mostrar/ocultar confirmación de contraseña
  const token = localStorage.token;
  useEffect(() => {
    checkToken(token)
      .then((response) => {
        setEmail(response.email);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden');
    } else if (!username && !newPassword) {
      setError('Por favor, complete al menos un campo');
    } else if (!validarContraseña(newPassword)) {
      setError('La contraseña debe tener al menos una mayúscula, un número y un caracter especial');
    } else {
      const datos = {
        name: username,
        email: email,
        password: newPassword
      };
      const json = JSON.stringify(datos);
      try {
        const response = await axios.put('https://cowsmyapi.mycowsrfi.com/api/users/update', {
          json: json
        },{ headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.token
        }});
        alert('Cuenta actualizada con éxito');
      } catch (error) {
        setError(error.message);
        alert('Error al actualizar la cuenta. Por favor, inténtalo de nuevo.');
      }
    }
  };

  const validarContraseña = (contraseña) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(contraseña);
  };

  return (
    <div className='fondouser'>
      <Cabecera />
      <form className="mi-formulario" onSubmit={handleSubmit}>
        <h2>Actualizar cuenta</h2>
        <br />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <label>Email: {email}</label>
        <br />
        <label>
          Nuevo nombre de usuario:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Nuevo nombre de usuario"
          />
        </label>
        <br />
        <div>
        <label>
          Nueva contraseña:
          <input
             type={showPassword ? 'text' : 'password'}
             value={newPassword}
             onChange={(e) => setNewPassword(e.target.value)}
             placeholder="Nueva contraseña"
          />
          <span className='showpass' onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', right: '60px', top: '53%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
          {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
          </span>
        </label>
        </div>
        <br />
        <label>
          Confirmar contraseña:
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmar contraseña"
          />
          <span className='ojo2' onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ position: 'absolute', right: '60px', top: '73%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
            {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
          </span>
        </label>
        <br />
        <button type="submit">Actualizar cuenta</button>
      </form>
    </div>
  );
};

export default Formulario;