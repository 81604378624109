import React, { useRef, useEffect, useState } from 'react';
import'../Styles/rfi.css';
import Modal from './Modal';
import { formToJSON } from 'axios';
import { useLocation } from 'react-router-dom';
import Cabecera from './CabeceraControler';
import { parse } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RFI() {
  const navigate = useNavigate();
  const location = useLocation();
    
      const { areteCode, cowName, breed } = location.state || {};

  let insercionAnterior;
  let colocacionPezonAnterior;
  let longitudPezon;
  let datoProfundidadUbre;
  let alturaUbrePosterior;
  let ligamentosSupensorMedio;
  let colocacionPezonPosterior;
  let anchuraUbreTrasera;
  let inclinacionUbre;
  const [modalVisible, setModalOpen] = useState(false);
  let datoAngularidad;
  let datoFortaleza;
  const [fecha_ultmod, setFUMod] = useState(null);
  let anguloPezuñas;
  let patasVistaLateral;
  let datoLocomocion;
  let patasVistaPosterior;
  let coxoFemoral;
  let diff = false;
  let datoEstatura;
  let datoProfundidad;
  let condicionCorporal;
  let anguloGrupa;
  let anchoGrupa;
  const [vpeso, setPeso] = useState(0);
  const [vtalla, setTalla] = useState(0);
  const [vhomogenidadCapa, setHomogenidadCapa] = useState('');
  let npeso;
  let ntalla;
  let nhomogenidadCapa;

  let score;
  let scores;
  let section;
  let porcentajeS1;
  let porcentajeS2;
  let porcentajeS3;
  let porcentajeS4;
  let porcentajeS5;
  let currentSection = 1;
  let seccion1 = false;
  let seccion2 = false;
  let seccion3 = false;
  let seccion4 = false;
  let seccion5 = false;
  let DisplayS1;
  let DisplayS2;
  let DisplayS3;
  let DisplayS4;
  let DisplayS5;
  let DisplaySP;
  let responseIngresoRFI;
  let sumaAprox=0;
  let sumaReal=0;

  const restriccionesPorRaza = {
    "Jersey": {
      peso: { min: 300, max: 400 },
      altura: { min: 1.0, max: 1.35 },
      homogenidad: ['Gris', 'Castaño']
    },
    "Holstein": {
      peso: { min: 580, max: 640 },
      altura: { min: 1.0, max: 1.5 },
      homogenidad: ['Negro', 'Rojo']
    },
    "Brown Swiss": {
      peso: { min: 600, max: 700 },
      altura: { min: 1.45, max: 1.55 },
      homogenidad: ['Gris', 'Cafe']
    },
    // Agrega más razas según sea necesario
  };
  const [sumaAproxmuestra, setSumaAproxmuestra] = useState(0);
  const [sumaRealmuestra, setSumaRealmuestra] = useState(0);
  //let confirmacion ;
  const [nombreEjemplar, setnombreEjemplar] = useState('');
  const [codigoArete, setcodigoArete] = useState('');

  useEffect(() => {
    setcodigoArete(areteCode);
    setnombreEjemplar(cowName );
    DisplayS1 = document.getElementById('dt-seccion1');
    DisplayS2 = document.getElementById('dt-seccion2');
    DisplayS2.style.display = 'none';
    DisplayS3 = document.getElementById('dt-seccion3');
    DisplayS3.style.display = 'none';
    DisplayS4 = document.getElementById('dt-seccion4');
    DisplayS4.style.display = 'none';
    DisplayS5 = document.getElementById('dt-seccion5');
    DisplayS5.style.display = 'none';
    DisplaySP = document.getElementById('dt-seccionP');
    DisplaySP.style.display = 'none';
    setFUMod(obtenerFechaActual());

  });
  const handleSave = () => {
  };
  const handleNextSection = () => {
  
  };
  const handleSectionComplete = (sectionNumber) => {
  };
  const calcularSeccion = () => {
    let p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15,p16,p17,p18,p19,p20,p21, contprop;
    switch (currentSection){
      case 1:
        insercionAnterior = parseFloat(document.getElementById('insercion-anterior').value);
        colocacionPezonAnterior = parseFloat(document.getElementById('colocacion-pezon-anterior').value);
        longitudPezon = parseFloat(document.getElementById('longitud-pezon').value);
        datoProfundidadUbre = parseFloat(document.getElementById('datoProfundidad-ubre').value);
        alturaUbrePosterior = parseFloat(document.getElementById('altura-ubre-posterior').value);
        ligamentosSupensorMedio = parseFloat(document.getElementById('ligamentos-suspensor-medio').value);
        colocacionPezonPosterior = parseFloat(document.getElementById('colocacion-pezon-posterior').value);
        anchuraUbreTrasera = parseFloat(document.getElementById('anchura-ubre-trasera').value);
        inclinacionUbre = parseFloat(document.getElementById('inclinacion-ubre').value);
        porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
        sumaReal = sumaReal + parseFloat(porcentajeS1);
        //restriccion prop 1
        if(!(insercionAnterior >0 && insercionAnterior < 10 )){
          alert('Inserción anterior fuera de rango');
        } else {

          if(insercionAnterior < 7){
            porcentajeS1 = (((7+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
            p1 = 1;
            diff=true;
          }else {
            p1 = 1;
          }
        }
        //restriccion prop 2
        if(!(colocacionPezonAnterior >0 && colocacionPezonAnterior < 7 )){
          alert('colocación del pezón fuera de rango');
        } else {
          if(colocacionPezonAnterior < 4){
              porcentajeS1 = (((insercionAnterior+4+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
              p2 = 1;
              diff=true;
            
          }else {
            p2 = 1;
          }
        }
        //restriccion prop 3
        if(!(longitudPezon >0 && longitudPezon < 7 )){
          alert('longitud del pezón fuera de rango');
        } else {
          if(longitudPezon < 4){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+4+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
              p3 = 1;
              diff=true;
            
          }else {
            p3 = 1;
          }
        }
        //restriccion prop 4
        if(!(datoProfundidadUbre >0 && datoProfundidadUbre < 7 )){
          alert('datoProfundidad de la ubre fuera de rango');
        } else {
          if(datoProfundidadUbre < 4){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+4+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
              p4 = 1;
              diff=true;
            
          }else {
            p4 = 1;
          }
        }
        //restriccion prop 5
        if(!(alturaUbrePosterior >0 && alturaUbrePosterior < 10 )){
          alert('altura de la ubre posterior fuera de rango');
        } else {
          if(alturaUbrePosterior < 7){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+7+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
              p5 = 1;
              diff=true;
            
          }else {
            p5 = 1;
          }
        }
        //restriccion prop 6
        if(!(ligamentosSupensorMedio >0 && ligamentosSupensorMedio < 10 )){
          alert('ligamento superior medio fuera de rango');
        } else {
          if(ligamentosSupensorMedio < 7){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+7+colocacionPezonPosterior+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
              p6 = 1;
              diff=true;
            
          }else {
            p6 = 1;
          }
        }
        //restriccion prop 7
        if(!(colocacionPezonPosterior >0 && colocacionPezonPosterior < 7 )){
          alert('colocación del pezón posterior fuera de rango');
        } else {
          if(colocacionPezonPosterior < 4){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+4+anchuraUbreTrasera+inclinacionUbre)*40)/66).toFixed(2);
              p7 = 1;
              diff=true;
            
          }else {
            p7 = 1;
          }
        }
        //restriccion prop 8
        if(!(anchuraUbreTrasera >0 && anchuraUbreTrasera < 10 )){
          alert('anchura de la ubre trasera fuera de rango');
        } else {
          if(anchuraUbreTrasera < 7){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+7+inclinacionUbre)*40)/66).toFixed(2);
              p8 = 1;
              diff=true;
            
          }else {
            p8 = 1;
          }
        }
        //restriccion prop 9
        if(!(inclinacionUbre >0 && inclinacionUbre < 7 )){
          alert('inclinación de la ubre fuera de rango');
        } else {
          if(inclinacionUbre < 4){
              porcentajeS1 = (((insercionAnterior+colocacionPezonAnterior+longitudPezon+datoProfundidadUbre+alturaUbrePosterior+ligamentosSupensorMedio+colocacionPezonPosterior+anchuraUbreTrasera+4)*40)/66).toFixed(2);
              p9 = 1;
              diff=true;
            
          }else {
            p9 = 1;
          }
        }
        contprop = p1+p2+p3+p4+p5+p6+p7+p8+p9;
        
        if(contprop == 9){
          sumaAprox = sumaAprox + parseFloat(porcentajeS1);
          currentSection = 2;
          DisplayS1.style.display = 'none';
          DisplayS2.style.display = 'block';
        }
        break;

      case 2:
        datoAngularidad = parseFloat(document.getElementById('datoAngularidad').value);
        datoFortaleza = parseFloat(document.getElementById('datoFortaleza').value);
        porcentajeS2 = (((datoAngularidad+datoFortaleza)*20)/18).toFixed(2);
        sumaReal = sumaReal + parseFloat(porcentajeS2);
         //restriccion prop 1
         if(!(datoAngularidad >0 && datoAngularidad < 10 )){
          alert('datoAngularidad fuera de rango');
        } else {

          if(datoAngularidad < 7){
              porcentajeS2 = (((7+datoFortaleza)*20)/18).toFixed(2);
              p10 = 1;
              diff=true;
            
          }else {
            p10 = 1;
          }
        }
         //restriccion prop 2
         if(!(datoFortaleza >0 && datoFortaleza < 10 )){
          alert('datoFortaleza fuera de rango');
        } else {

          if(datoFortaleza < 7){
              porcentajeS2 = (((datoAngularidad+7)*20)/18).toFixed(2);
              p11 = 1;
              diff=true;
            
          }else {
            p11 = 1;
          }
        }

        contprop = p10+p11;
        
        if(contprop == 2){
          sumaAprox = sumaAprox + parseFloat(porcentajeS2);
          currentSection = 3;
          DisplayS2.style.display = 'none';
          DisplayS3.style.display = 'block';
        }
        break;
      case 3:
        anguloPezuñas = parseFloat(document.getElementById('angulo-pezuñas').value);
        patasVistaLateral = parseFloat(document.getElementById('patas-vista-lateral').value);
        datoLocomocion= parseFloat(document.getElementById('datoLocomocion').value);
        patasVistaPosterior = parseFloat(document.getElementById('patas-vista-posterior').value);
        coxoFemoral = parseFloat(document.getElementById('coxo-femoral').value);
        porcentajeS3 = (((anguloPezuñas+patasVistaLateral+datoLocomocion+patasVistaPosterior+coxoFemoral)*20)/39).toFixed(2);
        sumaReal = sumaReal + parseFloat(porcentajeS3);
         //restriccion prop 1
         if(!(anguloPezuñas >0 && anguloPezuñas < 7 )){
          alert(' Angulo de pezuña fuera de rango');
        } else {

          if(anguloPezuñas < 4){
              porcentajeS3 = (((4+patasVistaLateral+datoLocomocion+patasVistaPosterior+coxoFemoral)*20)/39).toFixed(2);
              p12 = 1;
              diff=true;
            
          }else {
            p12 = 1;
          }
        }
         //restriccion prop 2
         if(!(patasVistaLateral >0 && patasVistaLateral < 7 )){
          alert(' Patas vista lateral fuera de rango');
        } else {

          if(patasVistaLateral < 4){
              porcentajeS3 = (((anguloPezuñas+4+datoLocomocion+patasVistaPosterior+coxoFemoral)*20)/39).toFixed(2);
              p13 = 1;
              diff=true;
            
          }else {
            p13 = 1;
          }
        }
         //restriccion prop 3
         if(!(datoLocomocion >0 && datoLocomocion < 10 )){
          alert(' Locomoción fuera de rango');
        } else {

          if(datoLocomocion < 7){
              porcentajeS3 = (((anguloPezuñas+patasVistaLateral+7+patasVistaPosterior+coxoFemoral)*20)/39).toFixed(2);
              p14 = 1;
              diff=true;
            
          }else {
            p14 = 1;
          }
        }
         //restriccion prop 4
         if(!(patasVistaPosterior >0 && patasVistaPosterior < 10 )){
          alert(' Ptas vistas posterior  fuera de rango');
        } else {

          if(patasVistaPosterior < 7){
              porcentajeS3 = (((anguloPezuñas+patasVistaLateral+datoLocomocion+7+coxoFemoral)*20)/39).toFixed(2);
              p15 = 1;
              diff=true;
            
          }else {
            p15 = 1;
          }
        }
         //restriccion prop 5
         if(!(coxoFemoral >0 && coxoFemoral < 10 )){
          alert(' Coxo femoral fuera de rango');
        } else {

          if(coxoFemoral < 7){
              porcentajeS3 = (((anguloPezuñas+patasVistaLateral+datoLocomocion+patasVistaPosterior+7)*20)/39).toFixed(2);
              p16 = 1;
              diff=true;
            
          }else {
            p16 = 1;
          }
        }
        contprop = p12+p13+p14+p15+p16;
        
        if(contprop == 5){
          sumaAprox = sumaAprox + parseFloat(porcentajeS3);
          currentSection = 4;
          DisplayS3.style.display = 'none';
          DisplayS4.style.display = 'block';
        }
        break;
      case 4:
        datoEstatura = parseFloat(document.getElementById('datoEstatura').value);
        datoProfundidad = parseFloat(document.getElementById('datoProfundidad').value);
        condicionCorporal= parseFloat(document.getElementById('condicion-corporal').value);
        porcentajeS4 = (((datoEstatura+datoProfundidad+condicionCorporal)*15)/24).toFixed(2);
        sumaReal = sumaReal + parseFloat(porcentajeS4);
         //restriccion prop 1
         if(!(datoEstatura >0 && datoEstatura < 10 )){
          alert(' datoEstatura fuera de rango');
        } else {

          if(datoEstatura < 7){
              porcentajeS4 = (((7+datoProfundidad+condicionCorporal)*15)/24).toFixed(2);
              p17 = 1;
              diff=true;
            
          }else {
            p17 = 1;
          }
        }
         //restriccion prop 2
         if(!(datoProfundidad >0 && datoProfundidad < 10 )){
          alert(' datoProfundidad fuera de rango');
        } else {

          if(datoProfundidad < 7){
              porcentajeS4 = (((datoEstatura+7+condicionCorporal)*15)/24).toFixed(2);
              p18 = 1;
              diff=true;
            
          }else {
            p18 = 1;
          }
        }
         //restriccion prop 3
         if(!(condicionCorporal >0 && condicionCorporal < 7 )){
          alert(' Condición corporal fuera de rango');
        } else {

          if(condicionCorporal < 4){
              porcentajeS4 = (((datoEstatura+datoProfundidad+4)*15)/24).toFixed(2);
              p19 = 1;
              diff=true;
            
          }else {
            p19 = 1;
          }
        }
        contprop = p17+p18+p19;
        
        if(contprop == 3){
          sumaAprox = sumaAprox + parseFloat(porcentajeS4);
          currentSection = 5;
          DisplayS4.style.display = 'none';
          DisplayS5.style.display = 'block';
        }
        break;
      case 5:
        anguloGrupa = parseFloat(document.getElementById('angulo-grupa').value);
        anchoGrupa = parseFloat(document.getElementById('ancho-grupa').value);
        porcentajeS5 = (((anguloGrupa+anchoGrupa)*5)/15).toFixed(2);
        sumaReal = sumaReal + parseFloat(porcentajeS5);
         //restriccion prop 1
         if(!(anguloGrupa >0 && anguloGrupa < 7 )){
          alert(' Angulo de grupa fuera de rango');
        } else {

          if(anguloGrupa < 4){
              porcentajeS5 = (((4+anchoGrupa)*5)/15).toFixed(2);
              p20 = 1;
              diff=true;
            
          }else {
            p20 = 1;
          }
        }

         //restriccion prop 1
         if(!(anchoGrupa >0 && anchoGrupa < 10 )){
          alert(' Ancho de grupa fuera de rango');
        } else {

          if(anchoGrupa < 7){
              porcentajeS5 = (((anguloGrupa+7)*5)/15).toFixed(2);
              p21 = 1;
              diff=true;
            
          }else {
            p21 = 1;
          }
        }
        contprop = p20+p21;
        
        if(contprop == 2){
          sumaAprox = sumaAprox + parseFloat(porcentajeS5);
          currentSection = 6;
          DisplayS5.style.display = 'none';
          DisplaySP.style.display = 'block';
        }
        //ingresando datos a la DB
        saveRFI();
        break;
      case 6:
        npeso = parseFloat(document.getElementById('peso').value).toFixed(2);
        ntalla = parseFloat(document.getElementById('talla').value).toFixed(2);
        nhomogenidadCapa = document.getElementById('homogenidad-capa').value;
        setSumaAproxmuestra(sumaAprox.toFixed(2));
        setSumaRealmuestra(sumaReal.toFixed(2));
        setPeso(npeso);
        setTalla(ntalla);
        setHomogenidadCapa(nhomogenidadCapa);
        if(diff){
          setModalOpen(true);
        } else {
          guardarReal();
        }
        
        
        //alert('se guardaran los datos');
          
        break;
        
    }
  }
  const obtenerFechaActual = () => {
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses son 0-indexados
    const dia = String(fecha.getDate()).padStart(2, '0');

    // Formato: YYYY-MM-DD
    return `${anio}-${mes}-${dia}`;
  };
  const saveRFI = async (e) => {
    //e.preventDefault();
    const datos = {
      codigo_arete: codigoArete.toString(),
      insercion_anterior_de_la_ubre: insercionAnterior,
      colocacion_de_pezon_anterior: colocacionPezonAnterior,
      longitud_de_pezon: longitudPezon,
      profundidad_de_la_ubre: datoProfundidadUbre,
      altura_de_la_ubre_posterior: alturaUbrePosterior,
      ligamentos_suspensor_medio: ligamentosSupensorMedio,
      colocacion_de_pezon_posterior: colocacionPezonPosterior,
      anchura_de_la_ubre_trasera: anchuraUbreTrasera,
      inclinacion_de_la_ubre: inclinacionUbre,
      angularidad: datoAngularidad,
      fortaleza: datoFortaleza,
      angulo_de_pezunas: anguloPezuñas,
      patas_vista_lateral: patasVistaLateral,
      locomocion: datoLocomocion,
      patas_vista_posterior: patasVistaPosterior,
      coxo_femoral: coxoFemoral,
      estatura: datoEstatura,
      profundidad: datoProfundidad,
      condicion_corporal: condicionCorporal,
      angulo_de_la_grupa: anguloGrupa,
      ancho_de_la_grupa: anchoGrupa,
      fecha_ultima_mod: fecha_ultmod
    };
    const json = JSON.stringify(datos);
    try{
      const response = await axios.post('https://cowsmyapi.mycowsrfi.com/api/rfi/agregar', {
        json: json
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.token
        }
      });
      responseIngresoRFI =  response.code;
      return;
      //if(responseIngresoRFI ==200)
    }catch(e){
      console.error(e);
    }
  };
  const closeModal = () => {
    setModalOpen(false);

  };

  const saveGenotipica = async () => {
    var dpeso = 0;
    var dtalla = 0;
    var dhomogenidadCapa = '';
    if(npeso == null || ntalla == null || nhomogenidadCapa == null){
      dpeso = parseFloat(vpeso);
      dtalla = parseFloat(vtalla);
      dhomogenidadCapa = vhomogenidadCapa;
    } else {
      dpeso = parseFloat(npeso);
      dtalla = parseFloat(ntalla);
      dhomogenidadCapa = nhomogenidadCapa;
    }
    var datoPunjate =0;
    if(sumaRealmuestra != 0){
      datoPunjate = parseFloat(sumaRealmuestra)
    } else {
      datoPunjate = parseFloat(sumaReal);
    }
    const datos = {
      codigo_arete: codigoArete.toString(),
      peso: dpeso,
      talla:  dtalla,
      homogenidad_capa:  dhomogenidadCapa,
      puntaje: parseFloat(datoPunjate),
    }
    const json = JSON.stringify(datos);
    try{
      const response = await axios.put('https://cowsmyapi.mycowsrfi.com/api/vacarfi/actualizar', {
        json: json
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.token
        }
      });
      responseIngresoRFI =  response.data.code;
      if(responseIngresoRFI == 200){
        //Navigate
        alert('Escore del ejemplar: ' + datos.puntaje);
        navigate('/generador', { replace: true, 
          state: { codigoArete } 
         });
      }
      return;
      //if(responseIngresoRFI ==200)
    }catch(e){
      console.error(e);
    }
  }

  const guardarReal = () => {
    //alert('se guardo los datos reales\n' + vpeso + '\n' + vtalla + '\n' + vhomogenidadCapa);
    saveGenotipica();

  };

  const guardarAprox = async () => {
    const datos = {
      codigo_arete: codigoArete.toString(),
      peso: parseFloat(vpeso),
      talla:  parseFloat(vtalla),
      homogenidad_capa:  vhomogenidadCapa,
      puntaje: parseFloat(sumaAproxmuestra),
    }
    const json = JSON.stringify(datos);
    try{
      const response = await axios.put('https://cowsmyapi.mycowsrfi.com/api/vacarfi/actualizar', {
        json: json
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.token
        }
      });
      responseIngresoRFI =  response.data.code;
      if(responseIngresoRFI == 200){
        //Navigate
        alert('Escore del ejemplar: ' + datos.puntaje);
        navigate('/generador', { replace: true, 
          state: { codigoArete } 
         });
      }
      return;
      //if(responseIngresoRFI ==200)
    }catch(e){
      console.error(e);
    }
  };

  return(
    <div className='fondo'>
    <Cabecera/>
    <br/><br/><br/><br/><br/>
    <div className="ejemplar-info">
          <label id='nombreeje'>Nombre del ejemplar:</label>
          <span>{nombreEjemplar}</span>
          <label id='codeje'>Código de la arete:</label>
          <span>{codigoArete}</span>
        </div>
      <div className='formulario-caracteristicas'>
        <h1>Clasificación Lineal</h1>
        
        <form className='rficow' >
        
        
        
          <details id='dt-seccion1' open>
            <summary>Sistema Mamario</summary>
            <div className="section-content">
            <label>Inserción anterior de la ubre:</label>
            <select
            id="insercion-anterior"
            required
            className={(insercionAnterior === "" || isNaN(parseInt(insercionAnterior))) ? "" : 
              (parseInt(insercionAnterior) === 9) ? "ideal" : 
                (parseInt(insercionAnterior) >= 7 && parseInt(insercionAnterior) <= 8) ? "bueno" : 
                  (parseInt(insercionAnterior) >= 4 && parseInt(insercionAnterior) <= 6) ? "regular" : "malo"}
        >
            <option value="">Selecciona un valor</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
        </select>
              <br />
              <label>Colocación de pezon anterior:</label>
        
<select
    id="colocacion-pezon-anterior"
    required
    className={(colocacionPezonAnterior === "" || isNaN(parseInt(colocacionPezonAnterior))) ? "" : 
      (parseInt(colocacionPezonAnterior) === 5) ? "ideal" : 
        (parseInt(colocacionPezonAnterior) === 4 || parseInt(colocacionPezonAnterior) === 6) ? "bueno" : "malo"}
>
    <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
</select>
              <br />
              <label>Longitud de pezón:</label>
              <select
    id="longitud-pezon"
    required
    className={(parseInt(longitudPezon) >= 1 && parseInt(longitudPezon) <= 6) ? 
        (parseInt(longitudPezon) === 5 ? "ideal" : 
        (parseInt(longitudPezon) === 4 || parseInt(longitudPezon) === 6) ? "bueno" : "malo") : ""}
>
    <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
</select>
              <br />
              <label>Profundidad de la ubre:</label>
               <select

                id="datoProfundidad-ubre"
                required
                className={(parseInt(datoProfundidadUbre) >= 1 && parseInt(datoProfundidadUbre) <= 6) ? (parseInt(datoProfundidadUbre) === 5 ? "ideal" : (parseInt(datoProfundidadUbre) === 4 || parseInt(datoProfundidadUbre) === 6) ? "bueno" : "malo") : ""}>
                <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
                </select>
              <br />
              <label>Altura de la ubre posterior:</label>
              <select
             
                id="altura-ubre-posterior"
                required
          
                className={(alturaUbrePosterior === "" || isNaN(parseInt(alturaUbrePosterior))) ? "" : 
                  (parseInt(alturaUbrePosterior) === 9) ? "ideal" : 
                    (parseInt(alturaUbrePosterior) >= 7 && parseInt(alturaUbrePosterior) <= 8) ? "bueno" : 
                      (parseInt(alturaUbrePosterior) >= 4 && parseInt(alturaUbrePosterior) <= 6) ? "regular" : "malo"}>
              <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
              <br />
              <label>Ligamentos suspensor medio:</label>
              <select
                
                id="ligamentos-suspensor-medio"
                required
                className={(ligamentosSupensorMedio === "" || isNaN(parseInt(ligamentosSupensorMedio))) ? "" : 
                  (parseInt(ligamentosSupensorMedio) === 9) ? "ideal" : 
                    (parseInt(ligamentosSupensorMedio) >= 7 && parseInt(ligamentosSupensorMedio) <= 8) ? "bueno" : 
                      (parseInt(ligamentosSupensorMedio) >= 4 && parseInt(ligamentosSupensorMedio) <= 6) ? "regular" : "malo"}
              >
                  <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
              <br />
              <label>Colocación de pezon posterior:</label>
              <select
                
                id="colocacion-pezon-posterior"
                required
                
                className={(colocacionPezonPosterior === "" || isNaN(parseInt(colocacionPezonPosterior))) ? "" : 
                  (parseInt(colocacionPezonPosterior) === 5) ? "ideal" : 
                    (parseInt(colocacionPezonPosterior) === 4 || parseInt(colocacionPezonPosterior) === 6) ? "bueno" : 
                      "malo"}
              >
              <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    </select>
              <br />
              <label>Anchura de la ubre trasera:</label>
              <select
                id="anchura-ubre-trasera"
                required
                className={(anchuraUbreTrasera === "" || isNaN(parseInt(anchuraUbreTrasera))) ? "" : 
                  (parseInt(anchuraUbreTrasera) === 9) ? "ideal" : 
                    (parseInt(anchuraUbreTrasera) >= 7 && parseInt(anchuraUbreTrasera) <= 8) ? "bueno" : 
                      (parseInt(anchuraUbreTrasera) >= 4 && parseInt(anchuraUbreTrasera) <= 6) ? "regular" : "malo"} >
                        <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
              <br />
              <label>Inclinación de la ubre:</label>
              <select
                
                id="inclinacion-ubre"
                required

                className={(inclinacionUbre === "" || isNaN(parseInt(inclinacionUbre))) ? "" : 
                  (parseInt(inclinacionUbre) === 5) ? "ideal" : 
                    (parseInt(inclinacionUbre) === 4 || parseInt(inclinacionUbre) === 6) ? "bueno" : 
                      "malo"}>
                        <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    </select>
              <br/>
              <button type="button" className="next-section"  onClick={() => {
                calcularSeccion(currentSection)
              }}
              >
                Siguiente
              </button>
                  
            </div>
          </details>
        
          <details id='dt-seccion2' open>
            <summary>Fuerza Lechera</summary>
            <div className="section-content">
              <label>Angularidad:</label>
              <select
                
                id="datoAngularidad"
                value={datoAngularidad}
                
                className={(datoAngularidad === "" || isNaN(parseInt(datoAngularidad))) ? "" : 
                  (parseInt(datoAngularidad) === 9) ? "ideal" : 
                    (parseInt(datoAngularidad) >= 7 && parseInt(datoAngularidad) <= 8) ? "bueno" : 
                      (parseInt(datoAngularidad) >= 4 && parseInt(datoAngularidad) <= 6) ? "regular" : "malo"}
              >
                 <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
              <br />
              <label>Fortaleza:</label>
              <select
                
                id="datoFortaleza"
                value={datoFortaleza}
                required
                min="1"
                max="9"
                pattern="[1-9]"
                placeholder="Rango: 1 - 9"
                className={(datoFortaleza === "" || isNaN(parseInt(datoFortaleza))) ? "" : 
                  (parseInt(datoFortaleza) === 9) ? "ideal" : 
                    (parseInt(datoFortaleza) >= 7 && parseInt(datoFortaleza) <= 8) ? "bueno" : 
                      (parseInt(datoFortaleza) >= 4 && parseInt(datoFortaleza) <= 6) ? "regular" : "malo"}
              >
              <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
              <br />
              <button type="button" className="next-section"  onClick={() =>{
               calcularSeccion(currentSection)
              }}>Siguiente</button>
            </div>
          </details>


    <details id='dt-seccion3' open>
      <summary>Patas y Pezuñas</summary>
      <div className="section-content">
        <label>Ángulo de pezuñas:</label>
        <select
          
          id="angulo-pezuñas"
          value={anguloPezuñas}
         
          className={(anguloPezuñas >= 4 && anguloPezuñas <= 6) ? (anguloPezuñas === 5 ? "ideal" : "bueno") : ""}
      >
        <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>

    </select>
        <br />
        <label>Patas vista lateral:</label>
        <select
         
          id="patas-vista-lateral"
          value={patasVistaLateral}
          required
          
          className={(patasVistaLateral >= 7 && patasVistaLateral <= 9) ? (patasVistaLateral === 9 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
 
    </select>
        <br />
        <label>Locomoción:</label>
        <select
         
          id="datoLocomocion"
          value={datoLocomocion}
          
          className={(datoLocomocion >= 7 && datoLocomocion <= 9) ? (datoLocomocion === 9 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
        <br />
        <label>Patas vista posterior:</label>
        <select
          id="patas-vista-posterior"
          value={patasVistaPosterior}
       
          placeholder="Rango: 1 - 9"
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
        <br />
        <label>Coxo femoral:</label>
        <select
          type="number"
          id="coxo-femoral"
          value={coxoFemoral}
          required
        
          className={(coxoFemoral >= 7 && coxoFemoral <= 9) ? (coxoFemoral === 9 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
          <br/>
        <button type="button" className="next-section" onClick={() =>{
          calcularSeccion(currentSection);
        }}>Siguiente</button>
      </div>
    </details>
    
    <details id='dt-seccion4' open>
      <summary>Tren Anterior y Capacidad</summary>
      <div className="section-content">
        <label>Estatura:</label>
        <select
          
          id="datoEstatura"
          value={datoEstatura}
          required
          
          className={(datoEstatura >= 7 && datoEstatura <= 9) ? (datoEstatura === 9 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
        <br />
        <label>Profundidad:</label>
        <select
       
          id="datoProfundidad"
          value={datoProfundidad}
        
          className={(datoProfundidad >= 7 && datoProfundidad <= 9) ? (datoProfundidad === 9 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
        <br />
        <label>Condición corporal:</label>
        <select
          id="condicion-corporal"
          value={condicionCorporal}
          required
         
       
          className={(condicionCorporal >= 4 && condicionCorporal <= 6) ? (condicionCorporal === 5 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
   
    </select>
          <br/>
        <button type="button" className="next-section" onClick={() =>{
          calcularSeccion(currentSection);
        }}>Siguiente</button>
      </div>
    </details>
    

    <details id='dt-seccion5' open>
      <summary>Grupa</summary>
      <div className="section-content">
        <label>Ángulo de la grupa:</label>
        <select
         
          id="angulo-grupa"
          value={anguloGrupa}
          required
        
          className={(anguloGrupa >= 4 && anguloGrupa <= 6) ? (anguloGrupa === 5 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>

    </select>
        <br />
        <label>Ancho de la grupa:</label>
        <select
          id="ancho-grupa"
          value={anchoGrupa}
          required
          className={(anchoGrupa >= 7 && anchoGrupa <= 9) ? (anchoGrupa === 9 ? "ideal" : "bueno") : ""}
        >
          <option value="">Selecciona un valor</option> 
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
    <option value="8">8</option>
    <option value="9">9</option>
    </select>
          <br/>
        <button type="button" className="next-section" onClick={() =>{
          calcularSeccion(currentSection);
        }}>Siguiente</button>
      </div>
    </details>
    

    <details id='dt-seccionP' open>
      <summary>Características Genotípicas</summary>
      <div className="section-content">
        <label>Peso (kg):</label>
        <input
          type="number"
          step="0.01"
          id="peso"
          min={restriccionesPorRaza[breed].peso.min}
          max={restriccionesPorRaza[breed].peso.max}
          placeholder={`Rango: ${restriccionesPorRaza[breed].peso.min} - ${restriccionesPorRaza[breed].peso.max} kg`}

        />
        <br />
        <label>Talla (m):</label>
        <input
          type="number"
          step="0.01"
          id="talla"
          min={restriccionesPorRaza[breed].altura.min}
          max={restriccionesPorRaza[breed].altura.max}
          placeholder={`Rango: ${restriccionesPorRaza[breed].altura.min} - ${restriccionesPorRaza[breed].altura.max} m`}

        />
        <br />
        <label>Homogenidad de la capa:</label>
        <select className='homogenidad_capa'
          id="homogenidad-capa"
        >
          <option  value="">Seleccione una opción</option>
          {restriccionesPorRaza[breed].homogenidad.map(h => (
          <option key={h} value={h}>{h}</option>
        ))}
        </select>
        <br/>
        <button className='save-button' type="button" id="save-button" onClick={() =>{
          calcularSeccion(currentSection);
        }}
      
  >Guardar</button>
      </div>
    </details>
    
        </form>
      </div>
      
      <Modal isOpen={modalVisible} onClose={closeModal}>
        <h2>información de confirmación</h2>
        <h3>Esta implementando valores fuera de rango en el RFI sus puntajes finales corresponden a:</h3>
        <div className='contenedor-tarjetas'>
          <div className="tarjeta-puntaje">
            <br/>
            Puntaje real
            <br/>
            {sumaRealmuestra}
            <br/>
          </div>
          <div className="tarjeta-puntaje">
            <br/>
            Puntaje aproximado
            <br/>
            {sumaAproxmuestra}
            <br/>
          </div>
        </div>
        <button className='btm_real' onClick={guardarReal}>Usar valor real</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button className='btm_aprox' onClick={guardarAprox}>Usar valor aproximado</button>
      </Modal>
      </div>

  );
 
}

export default RFI;