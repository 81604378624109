import axios from 'axios';
const checkToken = async (token) => {
    try {
      const response = await axios.get('https://cowsmyapi.mycowsrfi.com/api/checkToken', {
        headers: {
          Authorization: token
        }
        });
        if (response.status !== 200) {
          throw new Error('No se ha autenticado usuario');
        }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error de axios:', error.message);
      } else {
        console.error('Error:', error.message);
      }
      return { status: 400, error: 'No se ha autenticado usuario' };
    }
  };

export default checkToken