import React, { useState, useEffect,useRef  } from 'react';
import '../Styles/Ingreso.css';
import Cabecera from './CabeceraControler';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function ModificarEjemplar(props) {
  const navigate = useNavigate();
  const [preview, setPreview] = useState('');
  const [areteCode, setAreteCode] = useState(props.existingData ? props.existingData.codigo_arete : '');
  const [cowName, setCowName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [breed, setBreed] = useState('');
  const [imagenPath, setImagenPath] = useState('');
  const [imagenUpload, setImagenUpload] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [photoOption, setPhotoOption] = useState(''); // Opción por defecto
  const isMobile = /Mobi|Android/i.test(window.navigator.userAgent); // Verificar si es móvil
  const fileInputRef = useRef(null);
  const location = useLocation();
  const { arete_Code } = location.state || {};

  useEffect(() => {
    if (arete_Code != null) {
      setAreteCode(arete_Code);
    }
    cargarEjemplar();
  }, [props.existingData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newFileName = areteCode + '.jpg';
      const renamedFile = new File([file], newFileName, { type: file.type });
      setImagenUpload(renamedFile);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handlePhotoOptionChange = (e) => {
    setPhotoOption(e.target.value);
    if (e.target.value === 'camera' && isMobile) {
      fileInputRef.current.click(); 
    }
  };


  const subirImagenFTP = async () => {
    if (!imagenUpload) {
      alert('Por favor, seleccione una imagen para subir.');
      return;
    }

    const formData = new FormData();
    setImagenPath('https://mycowsrfi.com/dataimagenes/ejemplares/' + imagenUpload.name);
    formData.append('image', imagenUpload);
    try {
      const respuesta = await axios.post('https://cowsmyapi.mycowsrfi.com/api/subirImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('La imagen se ha cargado correctamente.'); // Cambia aquí para mostrar un alert
    } catch (error) {
      console.error(error);
      alert('Error al cargar la imagen.'); // Cambia aquí para mostrar un alert
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const datos = {
      codigo_arete: areteCode, // Mantener el código de arete sin cambios
      nombre: cowName,
      fecha_nacimiento: birthDate,
      raza: breed,
      foto: imagenPath,
    };
    const json = JSON.stringify(datos);
    try {
      const response = await axios.put(`https://cowsmyapi.mycowsrfi.com/api/vaca/${arete_Code}`, {
        json: json
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.token
        },
      });
      alert('Ejemplar actualizado correctamente.');
      navigate(-1);
    } catch (error) {
      console.error('Error al actualizar datos:', error);
    }
  };

  const cargarEjemplar = async () => {
    try {
      const response = await axios.get(`https://cowsmyapi.mycowsrfi.com/api/vacas/${arete_Code}`, {
        headers: {
          Authorization: localStorage.token
        }
      });
      if (parseInt(response.data.code) === 200) {
        setCowName(response.data.data.nombre);
        setBirthDate(response.data.data.fecha_nacimiento);
        setBreed(response.data.data.raza);
        setImagenUpload(response.data.data.foto);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='ingresocontainer'>
      <Cabecera />
      <div className='container-cow'>
      <form id="cow-form" className='cow-form'>
          <h2>Actualización de Ejemplar</h2>
          {isMobile ? ( // Solo muestra esto si es un dispositivo móvil
            <div className="form-group">
              <label htmlFor="photo-option">Selecciona opción para foto:</label>
              <select id="photo-option" value={photoOption} onChange={handlePhotoOptionChange}>
                <option value="select">Seleccionar archivo</option>
                <option value="camera">Tomar foto</option>
              </select>
              <input 
               ref={fileInputRef}
               className='selectimagen'
                type="file" 
                id="cow-picture" 
                name="cow-picture" 
                accept="image/*" 
                onChange={handleFileChange} 
                style={{ display: 'none' }} // Ocultar el input de archivo
              />
            </div>
          ) : ( // Si no es móvil, simplemente muestra el input de archivo
            <div className="form-group">
              <label htmlFor="cow-picture">Foto del Ejemplar:</label>
              <input 
              className='selectimagen'
                type="file" 
                id="cow-picture" 
                name="cow-picture" 
                accept="image/*" 
                onChange={handleFileChange} 
              />
            </div>
          )}
          <button className='subirimagen' type="button" onClick={subirImagenFTP}>Subir Imagen</button>
          <div className="form-group">
            <label htmlFor="cow-name">Nombre del Ejemplar:</label>
            <input 
              type="text" 
              id="cow-name" 
              value={cowName} 
              onChange={(e) => setCowName(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="birth-date">Fecha de Nacimiento:</label>
            <input 
              type="date" 
              id="birth-date" 
              value={birthDate} 
              onChange={(e) => setBirthDate(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="breed">Raza:</label>
            <input 
             
              type="text" 
              id="breed" 
              value={breed} 
              onChange={(e) => setBreed(e.target.value)} 
              required 
              disabled
            />
          </div>
          
          <button type="submit" onClick={handleSubmit}>Actualizar Ejemplar</button>
    
        </form>
      </div>
    </div>
  );
}

export default ModificarEjemplar;