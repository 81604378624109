import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faInfoCircle, faUser , faAddressCard, faHome, faSignOut, faUsers, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Cabecera.css';
import checkToken from './jwtCheck';
import { useState, useEffect } from 'react';

function Cabecera() {
  const token = localStorage.token;
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [navMobileOpen, setNavMobileOpen] = useState(false);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const userData = await checkToken(token);
        setTipoUsuario(userData.tipo_usuario);
      } catch (error) {
        console.error(error);
      }
    }
    cargarDatos();
  }, [token]);

  const navigate = useNavigate();

  const handleSalir = () => {
    localStorage.removeItem('token');
    navigate('/', { replace: true });
    window.location.reload();
  };

  const handleToggleMenu = () => {
    setNavMobileOpen(!navMobileOpen);
  };

  const renderNavigationLinks = () => {
    if (tipoUsuario == null || tipoUsuario === '' || tipoUsuario === 400) {
      return (
        <>
          <li>
            <Link id="boton_about" to="/About">
              <FontAwesomeIcon icon={faInfoCircle} /> Certificación
            </Link>
          </li>
          <li>
            <Link id="boton_login" to="/Login" className="active">
              <FontAwesomeIcon icon={faUser } /> Ingresar
            </Link>
          </li>
          <li>
            <Link id="boton_register" to="/Registro" className="active">
              <FontAwesomeIcon icon={faAddressCard} /> Registrar
            </Link>
          </li>
          <li>
            <a id="boton_manual" className="manual-pdf" href="https://mycowsrfi.com/docs/manualdeusuariorfi.pdf" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFilePdf} /> Manual de Usuario
            </a>
          </li>
        </>
      );
    } else if (tipoUsuario === "Administrador") {
      return (
        <>
          <li>
            <Link id="boton_inicio" to="/Home">
              <FontAwesomeIcon icon={faHome} /> Inicio
            </Link>
          </li>
          <li>
            <Link id="boton_usuarios" to="/Listausu">
              <FontAwesomeIcon icon={faUsers} /> Usuarios
            </Link>
          </li>
          <li>
            <Link id="boton_salir" to="/" onClick={handleSalir}>
              <FontAwesomeIcon icon={faSignOut} /> Cerrar sesión
            </Link>
          </li>
          <li>
            <a id="boton_manual" className="manual-pdf" href="https://mycowsrfi.com/docs/manualdeusuariorfi.pdf" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFilePdf} /> Manual de Usuario
            </a>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li>
            <Link id="boton_inicio" to="/Home">
              <FontAwesomeIcon icon={faHome} /> Inicio
            </Link>
          </li>
          <li>
            <Link id="boton_mi_cuenta" to="/Perfil">
              <FontAwesomeIcon icon={faUser } /> Mi cuenta
            </Link>
          </li>
          <li>
            <Link id="boton_salir" to="/" onClick={handleSalir}>
              <FontAwesomeIcon icon={faSignOut} /> Salir
            </Link>
          </li>
          <li>
            <a id="boton_manual" className="manual-pdf" href="https://mycowsrfi.com/docs/manualdeusuariorfi.pdf" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFilePdf} /> Manual de Usuario
            </a>
          </li>
        </>
      );
    }
  };

  return (
    <header>
      <nav>
        <Link id="boton_img" to="/">
          <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="About" className="imagen-pequena" />
        </Link>
        <ul className="nav-desktop">
          {renderNavigationLinks()}
        </ul>
        <button id="menu-toggle" aria-label="Open navigation menu" className="hidden-desktop toggle-button" onClick={handleToggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        {/* Menú desplegable */}
        <div id="nav-mobile" className={navMobileOpen ? 'nav-mobile open' : 'nav-mobile'}>
          <ul>
            {renderNavigationLinks()}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Cabecera;