import React, { useState, useEffect } from 'react';

import '../Styles/Inicio.css'
import Cabecera from './CabeceraControler';
/*import video from '../assets/video.mp4';*/
import YouTube from 'react-youtube';
import { useNavigate } from 'react-router-dom';
function Inicio(){
  const navigate = useNavigate();

const handleIngresar = () => {
  navigate('/Login', { replace: true });
};
  const [showRegister, setShowRegister] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const videoId = 'ZoLaMH1UCEs';
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1, // autoplay
      controls: 0, // disable controls
      disablekb: 1,
      loop: 1,
      mute: 1,
      playlist: videoId
    },
    allow: 'autoplay',
  };
  useEffect(() => {
    const token = localStorage.token; // Cambia esto según cómo almacenes tu token
    if (token) {
      setIsLoggedIn(true); // Si existe el token, el usuario está logueado
    }
  }, []);
  return(
    <div>
        <Cabecera/>
        <br/><br/><br/>
        <div id="youtube-container">
        <YouTube
      videoId={videoId}
      opts={opts} onReady={(event) => {
        event.target.playVideo();
      }} /></div>
      
      <div id="overlay">
        <div className='portada'>
        <h1 data-text="ESPOCH"></h1>
           <br/> <br/> <br/>
           {!isLoggedIn && ( // Solo mostrar el botón si el usuario NO está logueado
            <button className='ingresar' onClick={handleIngresar}>
              <span>Ingresar</span>
            </button>
          )}
        </div>
        
      </div>
        
    </div>
    
        
   
  );
}

export default Inicio;