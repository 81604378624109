import React, { useState, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { QRCodeCanvas } from 'qrcode.react';
import Cabecera from './CabeceraControler';
import '../Styles/generador.css'
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function UnificadoCodeGenerator() {
  const [code, setCode] = useState('');
  const barcodeCanvasRef = useRef(null);
  const qrCodeCanvasRef = useRef(null);
  const location = useLocation();
  const { codigoArete } = location.state || {};
  const navigate = useNavigate();

  let inputCode;

  const handleInputChange = () => {
    setCode(codigoArete);
  };
  const handleDownloadPDF = () => {
    navigate(`/Ejemplar/${codigoArete}`);
  }
  const handleDownload = () => {
    const barcodeDataUrl = barcodeCanvasRef.current.toDataURL();
    const qrCodeDataUrl = qrCodeCanvasRef.current.toDataURL();
    
    const link = document.createElement('a');
    link.href = barcodeDataUrl;
    link.download = `${codigoArete}-codigo-de-barras.png`;
    link.click();

    const qrLink = document.createElement('a');
    qrLink.href = qrCodeDataUrl;
    qrLink.download = `${codigoArete}-qrCode.png`;
    qrLink.click();
  };

  useEffect(() => {
    inputCode = document.getElementById('inputCode');
    inputCode.style.display = 'none';
    handleInputChange();
    if (barcodeCanvasRef.current && code.trim() !== '') {
      JsBarcode(barcodeCanvasRef.current, code, {
        format: 'CODE128',
        width: 3,
        height: 100,
        displayValue: true,
      });
    }
  }, [code]);

  return (
    <div>
      <Cabecera />
     
      <div className='gefomdo'>
      <br/> <br/> <br/>
        <div className='generador '>
        <input id='inputCode' type="text" value={code} onChange={handleInputChange} placeholder="Ingrese el código"/>
          <div className='mensaje' style={{ marginTop: '20px', color: 'red' }}>Datos guardados correctamente. Por favor descargue los códigos.</div> {/* Mensaje siempre visible */}
          
          <div  style={{ marginTop: '20px' }}>
            <h3>Código de barras:</h3>
            <canvas ref={barcodeCanvasRef} width={300} height={100} />
            <h3>Código QR:</h3>
            <QRCodeCanvas
              ref={qrCodeCanvasRef}
              value={code}
              size={256}
              level="H"
              includeMargin={true}
            />
          </div>
          <button className='btnGenerador' onClick={handleDownload}>Descargar códigos</button>
          <button className='btnGenerador' onClick={handleDownloadPDF} style={{ marginTop: '10px' }}>
            Descargar PDF
          </button>
          <a href="#" onClick={() => navigate(-1)} className="regrsar">
            Regresar
          </a>
        </div> 
      </div>
    </div>
  );
}

export default UnificadoCodeGenerator;